import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import './Header.scss';

import logo from '../../assets/img/logo.svg';
import logoWhite from '../../assets/img/logo-white.svg';

export default function Header() {
  const handlers = useSwipeable({
    onSwipedDown: (eventData) => toggleIsMenuActive()
  });

  const [isMenuActive, setIsMenuActive] = useState(false);

  function toggleIsMenuActive() {
    setIsMenuActive(prevIsMenuActive => !prevIsMenuActive);
  }

  return (
    <header className="header">
      <div className="header__logo">
        <Link to="/">
          <img src={logo} alt="Curacion" width="" height="" />
        </Link>
      </div>
      <div className="header__menu_btn">
        <motion.button
          whileHover={{ height: '12px' }}
          onClick={toggleIsMenuActive}
          className="header__menu_btn__icon"
        >
          <span></span>
          <span></span>
          <span></span>
        </motion.button>
      </div>
      <AnimatePresence>
        {isMenuActive &&
          <motion.nav
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{ duration: 0.6 }}
            className="header__nav"
            {...handlers}
          >
            <div className="header__nav__logo">
              <Link to="/">
                <img src={logoWhite} alt="Curacion" width="" height="" />
              </Link>
            </div>
            <div className="header__nav__close_menu_btn">
              <motion.button
                whileHover={{ scale: 1.1 }}
                onClick={toggleIsMenuActive}
                className="header__nav__close_menu_btn__icon"
              >
                <span></span>
                <span></span>
              </motion.button>
            </div>
            <div className="header__nav__menu">
              <ul className="header__nav__menu__list">
                <li className="header__nav__menu__list__item">
                  <Link to="/interiors">Interiors</Link>
                </li>
                <li className="header__nav__menu__list__item">
                  <Link to="/commercial">Commercial</Link>
                </li>
                <li className="header__nav__menu__list__item">
                  <Link to="/architecture">Architecture</Link>
                </li>
                <li className="header__nav__menu__list__item">
                  <Link to="/properties">Properties</Link>
                </li>
                <li className="header__nav__menu__list__item">
                  <Link to="/collection">Collection</Link>
                </li>
                <li className="header__nav__menu__list__item">
                  <Link to="/for-sale">For Sale</Link>
                </li>
                <li className="header__nav__menu__list__item">
                  <Link to="/studio">Studio</Link>
                </li>
                <li className="header__nav__menu__list__item">
                  <Link to="/press">Press</Link>
                </li>
                <li className="header__nav__menu__list__item">
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <span className="header__nav__copyright">Copyright Information &copy; 2022</span>
          </motion.nav>
        }
      </AnimatePresence>
    </header>
  );
}
