import { motion } from "framer-motion";

import './Filter.scss';

import FilterItem from './FilterItem';

export default function Filter(props) {

  function setActiveFilter(id) {
    props.filterProducts(id);
  }

  return (
    <motion.div
      initial={{ y: '-100%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: '-100%', opacity: 0 }}
      transition={{ duration: 0.6 }}
      className="filter"
    >
      <ul className="filter__list">
        <FilterItem key={null} id={null} name="All" is_active={null == props.active_filter ? true : false} setActiveFilter={setActiveFilter} />
        {props.filters.map((filter, index) => <FilterItem key={index} id={filter.id} name={filter.name} is_active={filter.id == props.active_filter ? true : false} setActiveFilter={setActiveFilter} /> )}
      </ul>
    </motion.div>
  );
}
