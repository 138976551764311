import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import Header from '../components/HeaderComponent/Header';
import Footer from '../components/FooterComponent/Footer';
import ProjectsIndex from '../components/ProjectsIndexComponent/ProjectsIndex';
import ProjectsIntro from '../components/ProjectsIntroComponent/ProjectsIntro';

function ArchitectureView() {

  const slug = 'architecture';

  const [dataState, setDataState] = useState({
    loading: true,
    data: {}
  });

  const pageSlug = 'architecture';
  const [page, setPage] = useState(null);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${pageSlug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(page => setPage(page))
  }, []);

  useEffect(() => {
    const endpoint = `${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_page_content&slug=${slug}`;
    fetch(endpoint)
      .then((res) => res.json())
      .then((data) => {
        setDataState({ loading: false, data: data });
      });
  }, [setDataState]);

  if (!page) return null;

  return (
    <>
      <Helmet>
        {page.metadata.seo_title ? <title>{page.metadata.seo_title}</title> : null}
        {page.metadata.seo_description ? <meta name="description" content={page.metadata.seo_description} /> : null}
      </Helmet>
      <Header />
      <div className="projects">
        {!dataState.loading &&
          <>
            <ProjectsIntro
              title={dataState.data.name}
              intro={dataState.data.content}
              image={dataState.data.thumbnail}
            />
            <ProjectsIndex title={'Architecture'} categories={[30]} />
          </>
        }
      </div>
      <Footer />
    </>
  );
}

export default ArchitectureView;
