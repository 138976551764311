import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion, useAnimation } from "framer-motion";

import './HomeMenu.scss';

export default function HomeMenu() {
  const interiorsControls = useAnimation(), collectionControls = useAnimation(), commercialControls = useAnimation(), architectureControls = useAnimation(), propertiesControls = useAnimation(), forSaleControls = useAnimation();

  const [open, setOpen] = useState(false);

  const [state, setState] = useState({
    loading: true,
    data: {
      interiors: [],
      styled: [],
      products: []
    }
  });

  useEffect(() => {
    let resolved = {};
    const promises = [
      fetch(`${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_projects&categories=3`)
        .then(res => res.json())
        .then(data => { resolved.interiors = data }),
      // fetch(`${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_projects&is_featured=true&categories=6`)
      //   .then(res => res.json())
      //   .then(data => { resolved.styled = data }),
      // fetch(`${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_products&is_featured=true`)
      //   .then(res => res.json())
      //   .then(data => { resolved.products = data })
    ];

    Promise.all(promises).then(data => {
      setState({
        loading: false,
        data: resolved
      });
    });
  }, []);

  useEffect(() => {
    interiorsControls.start((i) => ({
      rotate: 0
    }));
    commercialControls.start((i) => ({
      rotate: 0
    }));
    architectureControls.start((i) => ({
      rotate: 0
    }));
    propertiesControls.start((i) => ({
      rotate: 0
    }));
    collectionControls.start((i) => ({
      rotate: 0
    }));
    forSaleControls.start((i) => ({
      rotate: 0
    }));

    switch (open) {
      case 'interiors':
        interiorsControls.start((i) => ({
          rotate: 90
        }));
        break;
      case 'commercial':
        commercialControls.start((i) => ({
          rotate: 90
        }));
        break;
      case 'architecture':
        architectureControls.start((i) => ({
          rotate: 90
        }));
        break;
      case 'properties':
        propertiesControls.start((i) => ({
          rotate: 90
        }));
        break;
      case 'collection':
        collectionControls.start((i) => ({
          rotate: 90
        }));
        break;
      case 'for-sale':
        forSaleControls.start((i) => ({
          rotate: 90
        }));
        break;
    }
  }, [open]);

  const toggle = (e, id) =>  {
    e.preventDefault();
    setOpen(open === id ? undefined : id);
  };
  const isOpen = (id) => {
    return open === id;
  }

  return (
    <div className="home_menu">
      <AnimatePresence exitBeforeEnter>
        <motion.ul
          initial={{ x: '100px', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '100px', opacity: 0 }}
          transition={{ delay: 1, duration: 1 }}
          className="home_menu__list v_work"
        >
          <li className="home_menu__list__item v_sub_menu">
            <Link to="/interiors" onClick={(e) => { toggle(e, 'interiors') }}>
              Interiors
              <motion.span
                initial={{ rotate: 0 }}
                animate={interiorsControls}
                className="home_menu__list__item__arrow_icon"
              ></motion.span>
            </Link>
            <AnimatePresence>
              {isOpen('interiors') &&
                <motion.ul
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0, transition: { delay: 0.4 } }}
                  transition={{ duration: 0.4 }}
                >
                  {state.data.interiors.map((menuItem, index) => {
                    return (
                      <motion.li
                        key={index}
                        initial={{ x: 20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 20, opacity: 0, transition: { delay: 0 } }}
                        transition={{ delay: 0.4 }}
                      >
                        <Link to={"/projects/"+menuItem.slug}>{menuItem.name}</Link>
                      </motion.li>
                    )}
                  )}
                  <motion.li
                    initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 20, opacity: 0, transition: { delay: 0 } }}
                    transition={{ delay: 0.4 }}
                  >
                    <Link to="/interiors" className="v_index_link">View all Interiors</Link>
                  </motion.li>
                </motion.ul>
              }
            </AnimatePresence>
          </li>
          <li className="home_menu__list__item v_sub_menu">
            <Link to="/commercial" /*onClick={(e) => { toggle(e, 'commercial') }}*/>
              Commercial
              <motion.span
                initial={{ rotate: 0 }}
                animate={commercialControls}
                className="home_menu__list__item__arrow_icon"
              ></motion.span>
            </Link>

            {/*
              <AnimatePresence>
                {isOpen('styled') &&
                  <motion.ul
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0, transition: { delay: 0.4 } }}
                    transition={{ duration: 0.4 }}
                  >
                    {state.data.styled.map((menuItem, index) => {
                      return (
                        <motion.li
                          key={index}
                          initial={{ x: 20, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          exit={{ x: 20, opacity: 0, transition: { delay: 0 } }}
                          transition={{ delay: 0.4 }}
                        >
                          <Link to={"/projects/"+menuItem.slug}>{menuItem.name}</Link>
                        </motion.li>
                      )}
                    )}
                    <motion.li
                      initial={{ x: 20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: 20, opacity: 0, transition: { delay: 0 } }}
                      transition={{ delay: 0.4 }}
                    >
                      <Link to="/styled" className="v_index_link">View all Styled</Link>
                    </motion.li>
                  </motion.ul>
                }
              </AnimatePresence>
            */}
          </li>
          <li className="home_menu__list__item v_sub_menu">
            <Link to="/architecture" /*onClick={(e) => { toggle(e, 'styled') }}*/>
              Architecture
              <motion.span
                initial={{ rotate: 0 }}
                animate={architectureControls}
                className="home_menu__list__item__arrow_icon"
              ></motion.span>
            </Link>
          </li>
          <li className="home_menu__list__item v_sub_menu">
            <Link to="/collection" /*onClick={(e) => { toggle(e, 'collection') }}*/>
              Collection
              <motion.span
                initial={{ rotate: 0 }}
                animate={collectionControls}
                className="home_menu__list__item__arrow_icon"
              ></motion.span>
            </Link>

            {/*
              <AnimatePresence>
                {isOpen('collection') &&
                  <motion.ul
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0, transition: { delay: 0.4 } }}
                    transition={{ duration: 0.4 }}
                  >
                    {state.data.products.map((menuItem, index) => {
                      return (
                        <motion.li
                          key={index}
                          initial={{ x: 20, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          exit={{ x: 20, opacity: 0, transition: { delay: 0 } }}
                          transition={{ delay: 0.4 }}
                        >
                          <Link to={"/product/"+menuItem.slug}>{menuItem.name}</Link>
                        </motion.li>
                      )}
                    )}
                    <motion.li
                      initial={{ x: 20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: 20, opacity: 0, transition: { delay: 0 } }}
                      transition={{ delay: 0.4 }}
                    >
                      <Link to="/collection" className="v_index_link">View all Products</Link>
                    </motion.li>
                  </motion.ul>
                }
              </AnimatePresence>
            */}
          </li>
          <li className="home_menu__list__item v_sub_menu">
            <Link to="/properties">
              Properties
              <motion.span
                initial={{ rotate: 0 }}
                animate={propertiesControls}
                className="home_menu__list__item__arrow_icon"
              ></motion.span>
            </Link>
          </li>
          <li className="home_menu__list__item v_sub_menu">
            <Link to="/for-sale">
              For Sale
              <motion.span
                initial={{ rotate: 0 }}
                animate={forSaleControls}
                className="home_menu__list__item__arrow_icon"
              ></motion.span>
            </Link>
          </li>
        </motion.ul>
      </AnimatePresence>

      <AnimatePresence exitBeforeEnter>
        <motion.ul
          initial={{ x: '-100px', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '-100px', opacity: 0 }}
          transition={{ delay: 1, duration: 1 }}
          className="home_menu__list v_general"
        >
          <li className="home_menu__list__item">
            <Link to="/studio">Studio</Link>
          </li>
          <li className="home_menu__list__item">
            <Link to="/press">Press</Link>
          </li>
          <li className="home_menu__list__item">
            <Link to="/contact">Contact</Link>
          </li>
        </motion.ul>
      </AnimatePresence>
    </div>
  );
}
