import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

import './Gallery.scss';

import GalleryItem from './GalleryItem';

export default function Gallery(props) {
  const ref = useRef();

  const [childWidths, setChildWidths] = useState([]);
  const [width, setWidth] = useState(0);
  const [classNames, setClassNames] = useState('gallery');
  const [activeIndex, setActiveIndex] = useState(0);
  const [positionDistanceFromBottom, setPositionDistanceFromBottom] = useState(0);
  const [opacity, setOpacity] = useState(0);

  function updateWidth(index, childWidth) {
    let widths = childWidths;
    widths[index] = childWidth;
    setChildWidths(widths);
    if(childWidths.length === props.images.length) setTimeout(() => setOpacity(1), 3000);
    setWidth(childWidths.reduce((a, b) => a + b, 0));
  }

  // useEffect(() => {
  //   const handleWindowMouseMove = event => {
  //     const rect = ref.current.getBoundingClientRect();
  //     if(rect.x < 0 && event.screenX <= (window.innerWidth / 2)) {
  //       setClassNames('gallery prev_cursor');
  //     } else {
  //       setClassNames('gallery');
  //     }
  //   };
  //   window.addEventListener('mousemove', handleWindowMouseMove);

  //   return () => {
  //     window.removeEventListener('mousemove', handleWindowMouseMove);
  //   };
  // }, [ref]);

  useEffect(() => {
    const handleWindowScroll = event => {
      const rect = ref.current.getBoundingClientRect();

      setPositionDistanceFromBottom(-(rect.y + rect.height - window.innerHeight));
    };
    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, [ref]);

  // const handleScroll = (event) => {
  //   props.containerRef.current.scrollTo({
  //     top: 0,
  //     left: props.containerRef.current.scrollLeft + event.deltaY * 8,
  //     behavior: 'smooth'
  //   });
  // }

  // const enableScroll = (e) => {
  //   document.removeEventListener('wheel', preventDefault, false);
  // }

  // const disableScroll = (e) => {
  //   document.addEventListener('wheel', preventDefault, {
  //     passive: false,
  //   });
  // }

  const updateActiveIndex = (index) => {
    setActiveIndex(index);
  }

  return (
    <>
      <motion.ul
        // initial={{ x: '100%', opacity: 0 }}
        // animate={{ x: 0, opacity: 1 }}
        // exit={{ x: '-100%', opacity: 0 }}
        // transition={{ duration: 1.8 }}
        className={classNames}
        style={{ flexBasis: width, maxWidth: width, opacity: opacity }}
        ref={ref}
        // onWheel={handleScroll}
        // onMouseEnter={disableScroll}
        // onMouseLeave={enableScroll}
      >
        {props.images.map((image, index) => <GalleryItem
            key={index}
            index={index}
            updateWidth={updateWidth}
            setActiveIndex={updateActiveIndex}
            title={props.title}
            alt={image.alt}
            src={image.sizes.large}
            src_desktop={image.sizes['1536x1536']}
          />
        )}
        <div className="gallery__position" style={{ position: props.galleryInView ? 'fixed' : 'absolute', bottom: props.galleryInView ? positionDistanceFromBottom : 0 }}>
          <h3 className="gallery__position__title">{props.title}</h3>
          <span className="gallery__position__count">{activeIndex+1}/{props.images.length}</span>
        </div>
      </motion.ul>
    </>
  );
}

// function preventDefault(e) {
//   e = e || window.event;
//   if (e.preventDefault) {
//     e.preventDefault();
//   }
//   e.returnValue = false;
// }
