import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function ProductsIndexItem(props) {

  return (
    <motion.li
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="products_index__listing__item"
    >
      <a href={props.shop_link} target="_blank">
        <img className="products_index__listing__item__image" src={props.image} alt="" width="" height="" />
        <h3 className="products_index__listing__item__title">{props.name}</h3>
      </a>
    </motion.li>
  );
}
