import { useState } from "react";

export default function FilterItem(props) {

  // const [isActive, setIsActive] = useState(false);

  function makeActive() {
    // setIsActive(true);
    props.setActiveFilter(props.id);
  }

  return (
    <li onClick={makeActive} className={props.is_active ? 'filter__list__item active' : 'filter__list__item'}>{props.name}</li>
  );
}
