import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import './Product.scss';

import Gallery from '../GalleryComponent/Gallery';

export default function Product(props) {
  const ref = useRef();
  const { events } = useDraggable(ref);

  function hasMeta() {
    return props.hasOwnProperty('meta') && props.meta.length;
  }

  return (
    <div className="product">
      <h1 className="product__heading">{props.title}</h1>
      <div
        className="product__content"
        {...events}
        ref={ref}
      >
        <div className="product__content__info">
          <motion.h1
            initial={{ x: '-100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '-100%', opacity: 0 }}
            transition={{ duration: 1, delay: 0.6 }}
            className="product__content__info__heading"
          >
            {props.title}
          </motion.h1>
          <motion.div
            initial={{ y: '100%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: '100%', opacity: 0 }}
            transition={{ duration: 1, delay: 1.6 }}
          >
            <p className="product__content__info__introduction">{props.intro}</p>
            {hasMeta() &&
              <div className="product__content__info__meta">
                <h3 className="product__content__info__meta__heading"><u>Specifications:</u></h3>
                <ul className="product__content__info__meta__list">
                  {props.meta.map((data, index) => (
                    <li key={index} className="product__content__info__meta__list__item">
                      {data.name}
                      <br/>
                      {data.value}
                    </li>
                  ))}
                </ul>
              </div>
            }
            <a className="product__content__info__shop_link" href={props.shop_link} target="_blank">Shop Now</a>
          </motion.div>
        </div>
        <Gallery images={props.gallery} />
        <motion.div
          initial={{ y: '-100%', opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ flexBasis: '100vw', x: '-50vw', opacity: 0, transition: { duration: 1 } }}
          transition={{ duration: 1, delay: 1.6 }}
          className="product__content__nav"
        >
          {props.next_product_slug &&
            <>
              <Link to={`/product/${props.next_product_slug}`} className="product__content__nav__next_btn">Next Product</Link>
              <span className="product__content__nav__divider"></span>
            </>
          }
          <HashLink to="/collection#index" className="product__content__nav__back_btn">Back to all products</HashLink>
        </motion.div>
      </div>
    </div>
  );
}
