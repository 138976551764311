import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import Header from '../components/HeaderComponent/Header';
import Footer from '../components/FooterComponent/Footer';
import Project from '../components/ProjectComponent/Project';

function ProjectView() {
  const { slug } = useParams();

  const [dataState, setDataState] = useState({
    loading: true,
    data: {}
  });

  useEffect(() => {
    const endpoint = `${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_project&slug=${slug}`;
    fetch(endpoint)
      .then((res) => res.json())
      .then((data) => {
        setDataState({ loading: false, data: data });
      });
  }, [setDataState]);

  if(!dataState.data.name) return null;

  return (
    <>
      <div className="project_view">
        <Helmet>
          {dataState.data.metadata.seo_title ? <title>{dataState.data.metadata.seo_title}</title> : null}
          {dataState.data.metadata.seo_description ? <meta name="description" content={dataState.data.metadata.seo_description} /> : null}
        </Helmet>
        <Header />
        {!dataState.loading &&
          <Project
            title={dataState.data.name}
            intro={dataState.data.description}
            pinterest_link={dataState.data.metadata.pinterest_link}
            enquiries_email_address={dataState.data.metadata.enquiries_email_address}
            enquiries_whatsapp_number={dataState.data.metadata.enquiries_whatsapp_number}
            thumbnail={dataState.data.thumbnail}
            meta={dataState.data.credits}
            gallery={dataState.data.gallery}
            walkthrough={dataState.data.metadata.walkthrough}
            next_project_slug={dataState.data.next_project_slug}
            parent_category_slug={dataState.data.parent_category_slug}
          />
        }
      </div>
      <Footer />
    </>
  );
}

export default ProjectView;
