import { Link } from "react-router-dom";
import './Footer.scss';

import Newsletter from '../NewsletterComponent/Newsletter';

import logo from '../../assets/img/logo.svg';

export default function Footer() {

  return (
    <footer className="footer">
      <div className="footer__logo">
        <Link to="/">
          <img src={logo} alt="Curacion" width="148" height="23" />
        </Link>
      </div>

      <nav className="footer__nav">
        <ul className="footer__nav__list">
          <li className="footer__nav__list__item">
            <Link to="/interiors">Interiors</Link>
          </li>
          <li className="footer__nav__list__item">
            <Link to="/architecture">Architecture</Link>
          </li>
          <li className="footer__nav__list__item">
            <Link to="/collection">Collection</Link>
          </li>
          <li className="footer__nav__list__item">
            <Link to="/styled">Styled</Link>
          </li>
          <li className="footer__nav__list__item">
            <Link to="/properties">Properties</Link>
          </li>
        </ul>
        <ul className="footer__nav__list">
          <li className="footer__nav__list__item">
            <Link to="/studio">Studio</Link>
          </li>
          <li className="footer__nav__list__item">
            <Link to="/press">Press</Link>
          </li>
          <li className="footer__nav__list__item">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
        <ul className="footer__nav__list">
          <li className="footer__nav__list__item">
            Curación interior/architecture 
          </li>
          <li className="footer__nav__list__item">
            <a href="https://www.instagram.com/curacion_architecture.interior" target="_blank" rel="noreferrer">Instagram</a>
          </li>
          <li className="footer__nav__list__item">
            <a href="https://za.pinterest.com/DylanThomaz/" target="_blank" rel="noreferrer">Pinterest</a>
          </li>
        </ul>
        <ul className="footer__nav__list">
          <li className="footer__nav__list__item">
            Curación collection
          </li>
          <li className="footer__nav__list__item">
            <a href="https://www.instagram.com/curacion_collection" target="_blank" rel="noreferrer">Instagram</a>
          </li>
        </ul>
        <ul className="footer__nav__list">
          <li className="footer__nav__list__item">
            Curación properties
          </li>          
          <li className="footer__nav__list__item">
            <a href="https://www.instagram.com/curacion_properties_" target="_blank" rel="noreferrer">Instagram</a>
          </li>
        </ul>
        <ul className="footer__nav__list">
          <li className="footer__nav__list__item">
            Studio
          </li> 
          <li className="footer__nav__list__item">
            <a href="https://www.linkedin.com/company/curaci%C3%B3n-studios" target="_blank" rel="noreferrer">Linkedin</a>
          </li>
        </ul>
      </nav>

      <Newsletter />

      <span className="footer__copyright">Copyright Information &copy; 2022</span>
    </footer>
  );
}
