import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";

import './ProjectsIndex.scss';

import Filter from '../FilterComponent/Filter';

export default function ProjectsIndex(props) {
  // const controls = useAnimation();
  const [state, setState] = useState({
    loading: true,
    data: []
  });

  // useEffect(() => {
  //   controls.start((i) => ({
  //     opacity: 1,
  //     duration: 0.5,
  //     transition: { delay: i * 0.5 }
  //   }));
  // }, []);

  useEffect(() => {
    const categoriesList = props.categories.join(',');
    const endpoint = `${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_projects&categories=${categoriesList}`;
    fetch(endpoint)
      .then((res) => res.json())
      .then((data) => {
        setState({ loading: false, data: data });
      });
  }, [setState]);

  function hasFilters() {
    return props.hasOwnProperty('filters') && props.filters.length;
  }

  return (
    <div className="projects_index">
      {hasFilters() &&
        <Filter filters={props.filters} />
      }
      <h2 id="index" className="projects_index__title">{props.title}</h2>
      {state.data.length ?
        <ul className="projects_index__listing">
          {state.data.map((project, index) => {
            return (
              <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                // animate={controls}
                exit={{ opacity: 0 }}
                // custom={index}
                key={index}
                className="projects_index__listing__item"
              >
                <Link to={`/projects/${project.slug}`}>
                  <img className="projects_index__listing__item__image" src={project.thumbnail} alt="" width="" height="" />
                  <h3 className="projects_index__listing__item__title">
                    {(props.hasOwnProperty('show_titles') && props.show_titles === true) || !props.hasOwnProperty('show_titles') &&
                      <span>{project.name}</span>
                    }
                  </h3>
                </Link>
              </motion.li>
            );
          })}
        </ul>
      : null}
    </div>
  );
}
