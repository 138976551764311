import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import Header from '../components/HeaderComponent/Header';
import Footer from '../components/FooterComponent/Footer';
import ProductsIndex from '../components/ProductsIndexComponent/ProductsIndex';
import ProjectsIntro from '../components/ProjectsIntroComponent/ProjectsIntro';

export default function CollectionView() {

  const slug = 'collection';

  const [state, setState] = useState({
    loading: true,
    data: {
      page: null,
      product_categories: []
    }
  });

  const pageSlug = 'collection';
  const [page, setPage] = useState(null);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${pageSlug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(page => setPage(page))
  }, []);

  useEffect(() => {
    let resolved = {};
    const promises = [
      fetch(`${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_page_content&slug=${slug}`)
        .then(res => res.json())
        .then(data => { resolved.page = data }),
      fetch(`${process.env.REACT_APP_API_URL}/wp-admin/admin-post.php?action=get_product_categories`)
        .then(res => res.json())
        .then(data => { resolved.product_categories = data })
    ];

    Promise.all(promises).then(data => {
      setState({
        loading: false,
        data: resolved
      });
    });
  }, [setState]);

  if (!page) return null;

  return (
    <>
      <Helmet>
        {page.metadata.seo_title ? <title>{page.metadata.seo_title}</title> : null}
        {page.metadata.seo_description ? <meta name="description" content={page.metadata.seo_description} /> : null}
      </Helmet>
      <Header />
      <div className="projects">
        {!state.loading &&
          <>
            <ProjectsIntro
              title={state.data.page.name}
              intro={state.data.page.content}
              image={state.data.page.thumbnail}
            />
            {state.data.product_categories.length &&
              <ProductsIndex title={'Collection'} filters={state.data.product_categories} />
            }
          </>
        }
      </div>
      <Footer />
    </>
  );
}
