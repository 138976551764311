import { useRef, useState, useEffect } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { HashLink } from 'react-router-hash-link';

import './Project.scss';

import Gallery from '../GalleryComponent/Gallery';

export default function Project(props) {
  const ref = useRef();
  const introRef = useRef();
  const { events } = useDraggable(ref);
  const [ galleryInView, setGalleryInView ] = useState(false);

  function hasMeta() {
    return props.hasOwnProperty('meta') && props.meta.length;
  }

  const updateGalleryPosition = (right) => {
    if(right < 0) {
      setGalleryInView(true);
    } else {
      setGalleryInView(false);
    }
  }

  return (
    <>
      <div className="project_container">
        <div className="project">
          <motion.h1
            initial={{ x: '-100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '-100%', opacity: 0 }}
            transition={{ duration: 1, delay: 0.6 }}
            className="project__heading"
          >
            {props.title}
            {/*
              <span className="project__heading__position">1/18</span>
            */}
          </motion.h1>
          <div
            className="project__content"
            {...events}
            ref={ref}
            onScroll={(e) => updateGalleryPosition(introRef.current.getBoundingClientRect().right)}
          >
            <div ref={introRef} className="project__content__info">
              <motion.h1
                initial={{ x: '-100%', opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: '-100%', opacity: 0 }}
                transition={{ duration: 1, delay: 0.6 }}
                className="project__content__info__heading"
              >
                {props.title}
              </motion.h1>
              <motion.div
                initial={{ y: '100%', opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: '100%', opacity: 0 }}
                transition={{ duration: 1, delay: 1.6 }}
              >
                <p className="project__content__info__introduction">{props.intro}</p>
                {hasMeta() &&
                  <div className="project__content__info__meta">
                    {/*<h3 className="project__content__info__meta__heading">Collaborators &amp; Partners:</h3>*/}
                    <ul className="project__content__info__meta__list">
                      {props.meta.map((data, index) => (
                        <li key={index} className="project__content__info__meta__list__item">{data.name}</li>
                      ))}
                    </ul>
                  </div>
                }
                {props.pinterest_link &&
                  <p className="project__content__info__link">
                    <a href={props.pinterest_link} target="_blank" rel="noreferrer">View on Pinterest</a>
                  </p>
                }
                {props.enquiries_email_address &&
                  <p className="project__content__info__link">
                    <a href={`mailto:${props.enquiries_email_address}`}>Enquire via Email</a>
                  </p>
                }
                {props.enquiries_whatsapp_number &&
                  <p className="project__content__info__link">
                    <a href={`https://wa.me/${props.enquiries_whatsapp_number}`} target="_blank">Enquire via WhatsApp</a>
                  </p>
                }
                <h2 className="project__content__info__instruction">Scroll for more images</h2>
              </motion.div>
            </div>
            <Gallery containerRef={ref} title={props.title} images={props.gallery} galleryInView={galleryInView} />
            <motion.div
              initial={{ y: '-100%', opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ flexBasis: '100vw', x: '-50vw', opacity: 0, transition: { duration: 1 } }}
              transition={{ duration: 1, delay: 1.6 }}
              className="project__content__nav"
            >
              {props.next_project_slug &&
                <>
                  <Link to={`/projects/${props.next_project_slug}`} className="project__content__nav__next_btn">Next Project</Link>
                  <span className="project__content__nav__divider"></span>
                </>
              }
              {props.parent_category_slug &&
                <HashLink to={`/${props.parent_category_slug}#index`} className="project__content__nav__back_btn">Back to all projects</HashLink>
              }
            </motion.div>
          </div>
        </div>
      </div>
      {props.walkthrough ?
        <div className="project__walkthrough">
          <h2 className="project__walkthrough__title">Walkthrough</h2>
          <video className="project__walkthrough__video" controls>
            <source src={props.walkthrough.url} type="video/mp4" />
          </video>
        </div>
      : null}
    </>
  );
}
